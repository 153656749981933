import {CSSResult, html, LitElement, PropertyValues, unsafeCSS,} from 'lit';
import {classMap} from 'lit/directives/class-map.js';
import heartFilledNormal from '@breuni-ui/nui/dist/design-tokens/images/icons/nui-header_heart_filled_size=18.svg';
import heartNormal from '@breuni-ui/nui/dist/design-tokens/images/icons/nui-header_heart_size=18.svg';
import heartFilledLarge from '@breuni-ui/nui/dist/design-tokens/images/icons/nui-header_heart_filled_size=24.svg';
import heartLarge from '@breuni-ui/nui/dist/design-tokens/images/icons/nui-header_heart_size=24.svg';
import {customElement, property, state} from 'lit/decorators.js';
import { PromotionData } from '@cube/tracking-library-api/dist/@types/events/fragments.js';
import {WishlistMutationEvent} from '../../../@types/wishlist.js';
import css from './wishlist-button.scss';
import {config} from '../../../scripts/service/config.js';

enum ButtonSize {
  normal,
  large
}

function parseButtonSize(value: string): ButtonSize {
  switch (value) {
    case 'large':
      return ButtonSize.large;
    default:
      return ButtonSize.normal;
  }
}

@customElement('ents-wishlist-button')
export class WishlistButtonElement extends LitElement {
  @property({
    type: Array,
    attribute: 'initial-state',
  }) private initialState: string[] = [];

  @property({ type: Object, attribute: false }) private state: Record<string, boolean> = {};

  @property({ type: Number }) private syan: number = 0;

  @property({ type: String, attribute: 'color-id' }) private colorId?: string = undefined;

  @property({ type: String, attribute: 'article-id' }) private articleId?: string = undefined;

  @property({ type: Boolean, attribute: 'show-text' }) private showText: boolean = false;

  @property({ type: Boolean, attribute: 'no-color' }) private noColor: boolean = false;

  @property({ type: String }) private placement?: string = undefined;

  @property({ type: Object }) private promotion?: PromotionData = undefined;

  @property({
    type: String,
    attribute: 'size',
    converter: { fromAttribute: size => parseButtonSize(size ?? '') },
  }) private size: ButtonSize = ButtonSize.normal;

  @state()
  private animationActive: boolean = false;

  private resetAnimationTimeout: number | undefined;

  private readonly isTouchDevice: boolean = ('ontouchstart' in window || navigator.maxTouchPoints > 0);

  // use this syntax to make it work in Safari 14
  // working: static get styles() { return css`...` }
  // not working: static styles = css`...`
  static get styles(): CSSResult {
    return unsafeCSS(css);
  }

  private readonly onWishlistAddedEvent: (e: WishlistMutationEvent) => void;

  private readonly onWishlistRemovedEvent: (e: WishlistMutationEvent) => void;

  private selectedSlotNodes?: NodeListOf<Element> = undefined;

  private unselectedSlotNodes?: NodeListOf<Element> = undefined;

  attributeChangedCallback(name: string, _old: string | null, value: string | null) {
    super.attributeChangedCallback(name, _old, value);
    if (name === 'initial-state') {
      this.state = this.getInitialState();
    }
  }

  constructor() {
    super();

    this.onWishlistAddedEvent = (e: WishlistMutationEvent) => {
      const data = e.detail;
      this.state = { ...this.state, ...{ [data.identifier]: true } };
    };

    this.onWishlistRemovedEvent = (e: WishlistMutationEvent) => {
      const data = e.detail;
      this.state = { ...this.state, ...{ [data.identifier]: false } };
      this.resetAnimationImmediate();
    };

    this.state = {};
  }

  connectedCallback() {
    super.connectedCallback();

    document.addEventListener('ents:wishlist:added', this.onWishlistAddedEvent);
    document.addEventListener('ents:wishlist:removed', this.onWishlistRemovedEvent);

    this.state = this.getInitialState();

    this.selectedSlotNodes = this.querySelectorAll('[slot="selected"]');
    this.unselectedSlotNodes = this.querySelectorAll('[slot="unselected"]');
  }

  private getInitialState() {
    const initialState = this.initialState !== null ? this.initialState : [];
    return initialState.reduce((agg, v) => ({
      ...agg,
      [v]: true,
    }), {});
  }

  disconnectedCallback() {
    super.disconnectedCallback();

    document.removeEventListener('ents:wishlist:added', this.onWishlistAddedEvent);
    document.removeEventListener('ents:wishlist:removed', this.onWishlistRemovedEvent);

    if (!this.isTouchDevice) {
      this.removeEventListener('mouseleave', this.resetAnimationScheduled);
    }
  }

  protected updated(changedProperties: PropertyValues) {
    super.updated(changedProperties);

    this.setAttribute('selected', this.isOnWishlist() ? 'true' : 'false');
  }

  private getIdentifier() {
    return [this.syan, this.colorId].filter(e => !!e).join('-');
  }

  private isOnWishlist() {
    return this.state[this.getIdentifier()];
  }

  private getLabel() {
    if (this.isOnWishlist()) {
      return config.messageWishlistTextActive;
    }
    return config.messageWishlistTextInactive;
  }

  private handleClick(e: Event) {
    e.preventDefault();
    if (!this.isOnWishlist()) {
      this.triggerAnimation();
    }
    const eventName = this.isOnWishlist() ? 'ents:wishlist:remove' : 'ents:wishlist:add';
    document.dispatchEvent(new CustomEvent(eventName, {
      detail: {
        identifier: this.getIdentifier(),
        syan: this.syan,
        colorId: this.colorId,
        articleId: this.articleId,
        tracking: {
          placement: this.placement,
          promotion: this.promotion,
          context: this.isOnWishlist() ? 'remove_heart' : 'add_heart',
        },
      },
    }));
    e.stopPropagation();
  }

  private triggerAnimation() {
    if (this.isTouchDevice) {
      // directly schedule animation reset (touch)
      this.resetAnimationScheduled();
    } else {
      // schedule animation reset on mouse leave (non-touch)
      this.addEventListener('mouseleave', this.resetAnimationScheduled);
    }
    this.animationActive = true;
  }

  private resetAnimationScheduled() {
    if (!this.isTouchDevice) {
      this.removeEventListener('mouseleave', this.resetAnimationScheduled);
    }
    this.resetAnimationTimeout = window.setTimeout(() => {
      this.animationActive = false;
    }, 700); // wait 700ms to be certain that the animation is finished before resetting
  }

  private resetAnimationImmediate() {
    window.clearTimeout(this.resetAnimationTimeout); // in case an animation reset has been scheduled, abort the timer
    if (!this.isTouchDevice) {
      this.removeEventListener('mouseleave', this.resetAnimationScheduled);
    }
    this.animationActive = false;
  }

  private hasSlottedContent() {
    if (this.selectedSlotNodes === undefined || this.unselectedSlotNodes === undefined) {
      return false;
    }
    return this.selectedSlotNodes.length > 0 && this.unselectedSlotNodes.length > 0;
  }

  private renderSelectedSlot() {
    this.selectedSlotNodes?.forEach(n => n.removeAttribute('style'));
    return html`
      <slot name="selected"></slot>`;
  }

  private renderUnselectedSlot() {
    this.unselectedSlotNodes?.forEach(n => n.removeAttribute('style'));
    return html`
      <slot name="unselected"></slot>`;
  }

  private renderSlottedContent() {
    return this.isOnWishlist() ? this.renderSelectedSlot() : this.renderUnselectedSlot();
  }

  private renderNativeIcon() {
    if (this.size === ButtonSize.large) {
      return this.isOnWishlist() ? heartFilledLarge() : heartLarge();
    }
    return this.isOnWishlist() ? heartFilledNormal() : heartNormal();
  }

  private renderContent() {
    if (this.hasSlottedContent()) {
      return this.renderSlottedContent();
    }
    return html`${this.renderNativeIcon()}${this.showText ? html`<span class="label">${this.getLabel()}</span>` : ''}`;
  }

  protected render() {
    return html`
      <button @click="${this.handleClick}" type="button" aria-label="${this.getLabel()}"
              data-test-ents-product-on-wishlist="${this.isOnWishlist()}"
              class="${classMap({
    selected: this.isOnWishlist(),
    styled: !this.hasSlottedContent(),
    'no-color': this.noColor,
    text: this.showText,
    pulse: this.animationActive,
  })}"
      >
        ${this.renderContent()}
      </button>
    `;
  }
}
